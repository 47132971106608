import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { useHistory, useLocation, useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { PRODUCT_SUBCATEGORY } from "../../../constants/categories";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import no_image_found from "../../../assets/images/no_image_found.png";

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PreviousIcon } from '../../../assets/images/previous.svg';
import { ReactComponent as NextIcon } from '../../../assets/images/next.svg';
import { SearchContext } from "../../../context/searchContext";
import { Box } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SubCaregoryCard = ({ data, onMouseOver, isActive = false }) => {
    const classes = useStyles();
    const history = useHistory();
    const locationData = useLocation();
    const useQuery = () => {
        const { search } = locationData;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const categoryName = query.get("c") || "Agriculture";
    const updateQueryParams = () => {
        const params = new URLSearchParams({});
        // if(locationData.search === "" && query.get("c") === null){
        //     params.set("sc", data.value);
        //     history.push({ pathname: `/application/products`, search: params.toString() })
        // }else{
        params.set("c", categoryName);
        params.set("sc", data.value);
        history.push({ pathname: `/application/products`, search: params.toString() })
        // }
    };
    return (
        <>
            <div className='col-6 col-sm-6 col-md-4 col-lg-3'>
                <div className='cate_detail'>
                    <div className='cate_img' onMouseOver={onMouseOver} onClick={() => updateQueryParams()}>
                        <img src={data.imageUrl ? data.imageUrl : no_image_found} alt={`sub-cat-img-${data.value}`} />
                    </div>
                    <div className='cate_info'>
                        <p>{data?.value || ""}</p>
                        <button className='ord_btn' onClick={() => updateQueryParams()}>Order Now</button>
                    </div>
                </div>
            </div>

        </>
    )
};
const NewSubCaregoryCard = ({ data, onMouseOver, isActive = false }) => {
    const classes = useStyles();
    const history = useHistory();
    const locationData = useLocation();
    const useQuery = () => {
        const { search } = locationData;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const categoryName = query.get("c") || "Agriculture";
    const updateQueryParams = () => {
        const params = new URLSearchParams({});
        // if(locationData.search === "" && query.get("c") === null){
        //     params.set("sc", data.value);
        //     history.push({ pathname: `/application/products`, search: params.toString() })
        // }else{
        params.set("c", categoryName);
        params.set("sc", data.value);
        history.push({ pathname: `/application/products`, search: params.toString() })
        // }
    };
    return (
        <>
            <div className='mx-3'>
                <div className='cate_detail'>
                    <div className='cate_img' onMouseOver={onMouseOver} onClick={() => updateQueryParams()}>
                        <img src={data.imageUrl ? data.imageUrl : no_image_found} alt={`sub-cat-img-${data.value}`} />
                    </div>
                    <div className='cate_info'>
                        <p>{data?.value || ""}</p>
                        <button className='ord_btn' onClick={() => updateQueryParams()}>Order Now</button>
                    </div>
                </div>
            </div>

        </>
    )
};

const HomesubCategories = () => {
    // let { categoryName } = useParams();
    const classes = useStyles();
    const [activeSubCatIndex, setActiveSubCatIndex] = useState(0);
    const [subCatList, setSubCatList] = useState([]);
    const locationData = useLocation();
    const [page, setPage] = useState(0);
    const { locationData: deliveryAddressLocation } = useContext(SearchContext);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            slidesToSlide: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    function handleResize() {
        const width = window.innerWidth
        setScreenWidth(width);

    }
    useEffect(() => {
        window.addEventListener("resize", handleResize);
    }, []);

    const useQuery = () => {
        const { search } = locationData;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const categoryName = query.get("c") || 'Agriculture';

    useEffect(() => {
        if (categoryName) {
            setPage(0)
            const options = PRODUCT_SUBCATEGORY[categoryName];
            setSubCatList(options || []);
        }
    }, [categoryName, deliveryAddressLocation]);

    // const rowsPerPage = parseInt(screenWidth/120)-5;
    const rowsPerPage = parseInt(screenWidth / 120) - 5;
    const totalPageCount = Math.ceil(subCatList.length / rowsPerPage);
    return (
        <div className='mt-5 mb-5'>
            <div className="container">
                <div className='row'>
                    <div className='mb-4' item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h4">
                            Shop By Category
                        </Typography>
                    </div>
                </div>

                {/* <div className='row justify-content-center'> */}
                <Carousel responsive={responsive}
                    autoPlay={true}
                    showDots={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                >
                    {/* {
                    subCatList?.map((subCat, subCatIndex) => (
                        <SubCaregoryCard
                            key={`sub-cat-index-${subCatIndex}`}
                            data={subCat}
                            // isActive={subCatIndex === activeSubCatIndex}
                            onMouseOver={() => {
                                setActiveSubCatIndex(subCatIndex);
                            }}
                        />
                    ))
                } */}

                    {
                        subCatList?.map((subCat, subCatIndex) => (
                            <NewSubCaregoryCard
                                key={`sub-cat-index-${subCatIndex}`}
                                data={subCat}
                                // isActive={subCatIndex === activeSubCatIndex}
                                onMouseOver={() => {
                                    setActiveSubCatIndex(subCatIndex);
                                }}
                            />
                        ))
                    }
                </Carousel>
            </div>
        </div>
        // </div>
    )

};

export default HomesubCategories;