import { createContext, useState, useEffect } from "react";
import { getCall } from "../api/axios";
import { getValueFromCookie, AddCookieVisitor, getvisitorCookie } from "../utils/cookies";
import { v4 as uuidv4 } from "uuid";

export const CartContext = createContext({
  cartItems: [],
  setCartItems: () => { },
  getCartItems: () => { },
});

export function CartContextProvider({ children }) {
  const userCookie = getValueFromCookie("user");

  let user;

  if (userCookie) {
    try {
      user = JSON.parse(userCookie);
    } catch (error) {
      console.log("Error parsing user cookie:", error);
    }
  } else {
    let visitorCookie = getvisitorCookie("visitor");
    console.log('visitorCookie', visitorCookie);
    let vID = uuidv4();

    if (!visitorCookie) {
      console.log('user 29');
      AddCookieVisitor(vID);
    } else {
      console.log('user 32');
      try {
        visitorCookie = JSON.parse(visitorCookie);
        vID = visitorCookie?.id || uuidv4();
      } catch (error) {
        console.log("Error parsing visitor cookie:", error);
        vID = uuidv4();
      }
    }
    user = { id: vID };
    console.log('user', user);
  }

  // const userCookie = getValueFromCookie("user");

  // if (userCookie) {

  //   try {
  //     user = JSON.parse(userCookie);
  //   } catch (error) {
  //     console.log("Error parsing user cookie:", error);
  //   }
  // }
  // else {
  //   let visitorCookie = getvisitorCookie("visitor");
  //   console.log('visitorCookie', visitorCookie)
  //  let vID = uuidv4();
  //   if (!visitorCookie) {
  //     console.log('user 29')
  //     AddCookieVisitor(vID)
  //   }else{
  //     console.log('user 32')
  //     visitorCookie = JSON.parse(visitorCookie);
  //      vID = visitorCookie?.id || uuidv4()
  //   }
  //   user = { id: vID };
  //   console.log('user', user)
  // }

  const [cartItems, setCartItems] = useState([]);

  const getCartItems = async () => {
    try {
      const url = `/clientApis/v2/cart/${user.id}`;
      const res = await getCall(url);
      setCartItems(res);
    } catch (error) {
      console.log("Error fetching cart items:", error);
    }
  };

  useEffect(() => {
    if (!!Object.keys(user).length) getCartItems();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        fetchCartItems: getCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
