import { useEffect, useState } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export default function MapPointer(props) {
  const {
    center = [28.62, 77.09],
    zoom = 15,
    zoomControl = true,
    search = true,
    hybrid = false,
    location,
    setLocation = null,
  } = props;

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: "AIzaSyBcwNTfmtaSN1hc6Ur-mUyq8peFJd2C9xU",  // Replace with your API key
  });

  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [showPredictions, setShowPredictions] = useState(true);

  useEffect(() => {
    console.log('Place Predictions:', placePredictions);
  }, [placePredictions]);

  useEffect(() => {
    if (selectedValue) {
      const { lat, lng } = selectedValue;
      console.log('Selected Place:', selectedValue);
      if (lat && lng) {
        setLocation(selectedValue);
        setShowPredictions(false);
      } else console.log("Location not found. Please try moving map.");
    }
  }, [selectedValue]);

  const handlePlaceSelect = (place) => {
    const { description, place_id, structured_formatting } = place;
    const { main_text } = structured_formatting;
   
    // Get place details
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId: place_id }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();
        let street = main_text;
        let city = '';
        let state = '';
        let pincode = '';

        result.address_components.forEach(component => {
          const types = component.types;
          if (types.includes('street_number') || types.includes('route')) {
            street = street ? `${street} ${component.long_name}` : main_text;
          }
          if (types.includes('locality')) {
            city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            state = component.long_name;
          }
          if (types.includes('postal_code')) {
            pincode = component.long_name;
          }
        });

        setSelectedValue({
          description,
          lat,
          lng,
          street,
          city,
          state,
          pincode
        });
      }
    });
  };

  return (
    <div className="slc_address">
      <span>Select Address</span>
      <input
        type="text" className="add_value" value={value}
        placeholder="Select Address"
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
          setShowPredictions(true);
        }}
      />

      <div>
        {showPredictions && !isPlacePredictionsLoading && (
          <ListGroup as="ul">
            {placePredictions.map((item, index) => (
              <ListGroup.Item
                as="li"
                key={index}
                onClick={() => handlePlaceSelect(item)}
                style={{ cursor: "pointer" }}
              >
                {item.description}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </div>
  );
}
