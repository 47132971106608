import React, { useState } from 'react';
import useStyles from './style';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import logo from "../../../assets/images/AppLogo.svg";
import { ReactComponent as CallIcon } from "../../../assets/images/call.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/images/whatsapp.svg";
import appStoreImage from "../../../assets/images/appStore.png";
import playStoreImage from "../../../assets/images/playStore.png";
import { removeCookie } from "../../../utils/cookies";
import { categoryList, PRODUCT_SUBCATEGORY } from '../../../constants/categories';
import { useHistory, useLocation } from "react-router-dom";
import HesaLogo from '../../../assets/images/footer-logo.png';

const Footer = () => {
    const classes = useStyles();
    const history = useHistory();
    const locationData = useLocation();
    const useQuery = () => {
        const { search } = locationData;
        return React.useMemo(() => new URLSearchParams(search), [search]);
    };
    let query = useQuery();
    const [subcat, setsubcat] = useState(PRODUCT_SUBCATEGORY['Agriculture'] || []);

    const updateQueryParams = (catName) => {
        // if(locationData.search === "" && query.get("c") === null){
        //     history.push(`/application/products?sc=${catName}`)
        // }else{
        const params = new URLSearchParams({});
        params.set('c', 'Agriculture')
        params.set('sc', catName)
        history.replace({ pathname: locationData.pathname, search: params.toString() });
        // }

    };
    return (
        <Box
            component="footer"
            className={classes.footerContainer}
        >
            <div className='container'>
            <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className='reg_office'>
                    <div>
                        <img
                            src={HesaLogo}
                            alt="logo"
                            className={classes.footerAppLogo}
                            onClick={() => {
                                // removeCookie("search_context");
                                history.push("/application/products");
                            }}
                        />
                    </div>
                    <div
                        className={classes.contactUsContainer}
                    >
                        <Typography variant="h6" color="white">Contact Us</Typography>
                        <div className={classes.contactUsItem}>
                           
                            <div className={classes.itemDetailsContainer}>
                                <Typography className={classes.itemDetailsLabel} variant="body" component="div" color="white">Registered Office</Typography>
                                <Typography className={classes.itemDetailsValue} variant="body" component="div" color="white">Hesa Enterprises Private Limited 2nd floor, 136, Saipuri Colony, Hastinapuri Colony, Sainikpuri, Hyderabad - 500094, Telangana, India</Typography>
                            </div>
                        </div>
                
                        <div className={classes.contactUsItem}>
                          
                            <div className={classes.itemDetailsContainer}>
                                <Typography className={classes.itemDetailsLabel} variant="body" component="div" color="white">Call Us : 1800-889-1976</Typography>
                           
                            </div>
                        </div>
                    </div>
                    {/* <div
                        className={classes.appsContainer}
                    >
                        <Typography variant="h6" color="white">Download App</Typography>
                        <div className={classes.appsItem}>
                            <img className={classes.appImages} src={appStoreImage} alt="App Store" />
                            <img className={classes.appImages} src={playStoreImage} alt="App Store" />
                        </div>
                    </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                    <Typography className='f_title' variant="h6" color="white">
                        Most Popular Categories
                    </Typography>

                    <ul className={classes.listContainer}>
                        {
                            subcat.map((item, index) => (
                                <li key={`category-${index}`} className={classes.listStyle} onClick={() => updateQueryParams(item.key)}>
                                    <Typography
                                        className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                                    >
                                        {item.key}
                                    </Typography>
                                </li>
                            ))
                        }
                    </ul>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                    <Typography className='f_title' variant="h6" color="white">
                        Customer Services
                    </Typography>

                    <ul className={classes.listContainer}>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                About Us
                            </Typography>
                        </li>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                Terms & Conditions
                            </Typography>
                        </li>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                FAQ
                            </Typography>
                        </li>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                Privacy Policy
                            </Typography>
                        </li>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                E-waste Policy
                            </Typography>
                        </li>
                        <li className={classes.listStyle}>
                            <Typography
                                className={classes.itemDetailsLabel} variant="body" component="div" color="white"
                            >
                                Cancellation & Return Policy
                            </Typography>
                        </li>
                    </ul>
                </Grid>
                <Grid className='y_circle' item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <div className={classes.circleOne}></div>
                    <div className={classes.circleTwo}></div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <div className='disclaimer'>
                    <h6 className='f_title'>Disclaimer</h6>
                    <p>All instances of "ONDC" and "Open Network For Digital Commerce" refer to "ONDC Network" and "ONDC Protocol" and not ONDC the Section 8 company. Hesa is one of the network participants of the ONDC Network and offers Buyer and Seller apps in accordance with the ONDC Protocol.</p>
                    </div>
                    </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                    style={{ textAlign: 'center', marginTop: '25px' }}
                >
                    <Box
                        component={"div"}
                        className={classes.divider}
                    />
                    <Typography
                        variant="body1"
                        color="white"
                        className={classes.copyright}
                    >
                        © 2024 Hesa - All Rights Reserved
                    </Typography>
                </Grid>
            </Grid>
            </div>
        </Box>
    );

};

export default Footer;