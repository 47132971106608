import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

export function AddCookie(key, value) {
  const cookie_expiry_time = new Date();
  cookie_expiry_time.setTime(cookie_expiry_time.getTime() + 3600 * 1000); // expires in 1 hour
  Cookies.set(key, value, {
    expires: cookie_expiry_time,
  });
}

export function getValueFromCookie(key) {
  return Cookies.get(key);
}

export function AddCookieVisitor(key) {
  
  let value =  JSON.stringify({ name: 'New user', id: key })
  const cookie_expiry_time = new Date();
  cookie_expiry_time.setTime(cookie_expiry_time.getTime() + 3600 * 1000); // expires in 1 hour
  Cookies.set('visitor', value, {
    expires: cookie_expiry_time,
  });
}

export function getvisitorCookie(key) {
  return Cookies.get('visitor') || '';
}

export function removeCookie(key) {
  console.log('remove working', key)
  Cookies.remove(key);
}

export function deleteAllCookies() {
  console.log('delete working')
  const cookies = document.cookie.split(";");
  cookies.map((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    Cookies.remove(name.trim());
    return null;
  });
}
